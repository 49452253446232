import { configureStore, combineReducers } from "@reduxjs/toolkit";

import ecommerce from "./ecommerce";
import auth from "./auth";

const rootReducer = combineReducers({
  ecommerce,
  auth
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
