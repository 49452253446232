import { IBooking } from "../booking/booking/types";

export const gtm = (
  action:
    | "addToCart"
    | "removeFromCart"
    | "viewCart"
    | "startedCheckout"
    | "addPaymentInfo"
    | "addShippingInfo"
    | "purchase"
    | "viewItem",
  data: Record<string, any> & { booking?: IBooking }
) => {
    
  const dataLayer = (window as any)?.["dataLayer"];

  if (!dataLayer) return;

  const { booking } = data;

  const items = booking?.items || [];
  const currencyCode = booking?.currencyCode;

  switch (action) {
    case "addToCart":
      if (booking) {
        dataLayer.push({
          event: "add_to_cart",
          ecommerce: getEcommerce(booking),
        });
      }
      break;
    case "removeFromCart":
      if (booking) {
        dataLayer.push({
          event: "remove_from_cart",
          ecommerce: getEcommerce(booking),
        });
      }
      break;
    case "viewCart":
      if (booking) {
        dataLayer.push({
          event: "view_cart",
          ecommerce: getEcommerce(booking),
        });
      }
      break;
    case "startedCheckout":
      if (booking) {
        dataLayer.push({
          event: "begin_checkout",
          ecommerce: getEcommerce(booking),
        });
      }
      break;
    case "addPaymentInfo":
      if (booking) {
        dataLayer.push({
          event: "add_payment_info",
          ecommerce: getEcommerce(booking),
        });
      }
      break;
    case "addShippingInfo":
      if (booking) {
        dataLayer.push({
          event: "add_shipping_info",
          ecommerce: getEcommerce(booking),
        });
      }
      break;
    case "purchase":
      if (booking) {
        dataLayer.push({
          event: "purchase",
          ecommerce: getEcommerce(booking),
        });
      }
      break;
    case "viewItem":
      //   if (product) {
      //     const schema = jsonParseString(product?.schema ?? "{}");

      //     const seo = Array.isArray(schema)
      //       ? schema.find((s) => s.salesChannelId === salesChannel.id)
      //       : schema;

      //     dataLayer.push({
      //       event: "view_item",
      //       ecommerce: {
      //         currency: currencyCode,
      //         valye: product.currentPrice,
      //         items: [
      //           {
      //             item_id: product.id || product.SKUCode,
      //             item_name: product.SKUName,
      //             item_brand: seo.brand,
      //             item_category: seo.category,
      //             item_variant: product.shortName,
      //             price: truncValue(product.currentPrice),
      //             currency: currencyCode,
      //             quantity: 1,
      //           },
      //         ],
      //       },
      //     });
      //   }
      break;
  }
};

const truncValue = (value: number) => {
  return Math.trunc(value * 100) / 100;
};

const getEcommerce = (booking: IBooking) => {
  return {
    value: truncValue(booking.totalAmount),
    currency: booking.currencyCode,
    items: (booking.items || []).map((item) => {
      //   const schema = JSON.parse(item?.schema ?? "{}");
      let seoData: any = {};
      //   if (Array.isArray(schema)) {
      //     seoData = schema.find(
      //       (s) => s.salesChannelId === salesChannel.id
      //     );
      //   } else {
      //     seoData = schema || {};
      //   }

      return {
        //   affiliation: salesChannel?.name,
        item_id: item.id,
        item_category: 0, //Can we pull the Xero Product Category here?
        item_brand: seoData?.brand,
        item_name:
          item.service.route.from.name +
          " - " +
          item.service.route.to.name +
          " @ " +
          item.service.depart,
        price: truncValue(item.totalAmount),
        currency: booking.currencyCode,
        quantity: item.quantity,
      };
    }),
  };
};
