import Route from './route';
import Service from './service';
import Booking from './booking';
import Location from './location';

export const BookingService = {
  Route,
  Booking,
  Location,
  Service,
};
