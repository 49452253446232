import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AddServiceToBookingInput,
  IBooking,
  RemoveBookingItemInput,
  SetBillingAddressInput,
  SetCustomerInput,
  UpdateBookingItemInput,
} from "../../services/booking/booking/types";
import { BookingService } from "../../services/booking";
import { ServiceResponse } from "../../services/types";
import { gtm } from "../../services/gtm";

const STORE_NAME = "ecommerce";
export const FLUID_APP_WEBSITE_BOOKING_ID_KEY = "fluidwbid";

export const setStep = createAsyncThunk(
  `${STORE_NAME}/setStep`,
  async (step: EcommerceSteps) => {
    return step;
  }
);

export const getWebsiteBooking = createAsyncThunk(
  `${STORE_NAME}/getBooking`,
  async () => {
    const bookingId =
      localStorage.getItem(FLUID_APP_WEBSITE_BOOKING_ID_KEY) || "";
    if (!bookingId || bookingId == "") {
      localStorage.removeItem(FLUID_APP_WEBSITE_BOOKING_ID_KEY);
      return undefined;
    }
    const res = await BookingService.Booking.getWebsiteBooking(bookingId);
    return res;
  }
);

export const addServiceToBooking = createAsyncThunk(
  `${STORE_NAME}/addServiceToBooking`,
  async (params: AddServiceToBookingInput) => {
    const res = await BookingService.Booking.addServiceToBooking(params);
    gtm("addToCart", { booking: res?.data });
    return res;
  }
);

export const updateBookingItem = createAsyncThunk(
  `${STORE_NAME}/updateBookingItem`,
  async (params: UpdateBookingItemInput) => {
    const res = await BookingService.Booking.updateBookingItem(params);
    return res;
  }
);

export const removeBookingItem = createAsyncThunk(
  `${STORE_NAME}/removeBookingItem`,
  async (params: RemoveBookingItemInput) => {
    const res = await BookingService.Booking.removeBookingItem(params);
    return res;
  }
);

export const setCustomer = createAsyncThunk(
  `${STORE_NAME}/setCustomer`,
  async (params: SetCustomerInput) => {
    const res = await BookingService.Booking.setCustomer(params);
    return res;
  }
);

export const setBillingAddress = createAsyncThunk(
  `${STORE_NAME}/setBillingAddress`,
  async (params: SetBillingAddressInput) => {
    const res = await BookingService.Booking.setBillingAddress(params);
    return res;
  }
);

export enum EcommerceSteps {
  CustomerDetails = 0,
  BillingDetails = 1,
  Payment = 2,
}

type EcommerceStateType = {
  booking?: IBooking;
  step: EcommerceSteps;
  currencyCode?: string;
  lastOpen?: string;
};
const INITIAL_STATE: EcommerceStateType = {
  step: EcommerceSteps.CustomerDetails,
};

const ecommerceSlice = createSlice({
  name: STORE_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    getEcommerceState: (state: EcommerceStateType) => {
      return state;
    },
    updateBooking: (state: EcommerceStateType, action: any) => {
      const booking = action.payload as IBooking;

      if (booking?.id) {
        localStorage.setItem(FLUID_APP_WEBSITE_BOOKING_ID_KEY, booking.id);
      }

      return {
        ...state,
        booking,
      };
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(
      setStep.fulfilled,
      (state: EcommerceStateType, action: { payload: EcommerceSteps }) => {
        state.step = action.payload;
      }
    );
    builder.addCase(
      getWebsiteBooking.fulfilled,
      (state: EcommerceStateType, action: any) => {
        const serviceResponse = action?.payload as ServiceResponse<IBooking>;
        state.booking = serviceResponse?.data;
        state.lastOpen = undefined;
      }
    );
    builder.addCase(
      addServiceToBooking.fulfilled,
      (state: EcommerceStateType, action: any) => {
        const serviceResponse = action.payload as ServiceResponse<IBooking>;
        state.booking = serviceResponse?.data;
        state.lastOpen = new Date().toISOString();
      }
    );
    builder.addCase(
      removeBookingItem.fulfilled,
      (state: EcommerceStateType, action: any) => {
        const serviceResponse = action.payload as ServiceResponse<IBooking>;
        state.booking = serviceResponse?.data;
      }
    );
    builder.addCase(
      updateBookingItem.fulfilled,
      (state: EcommerceStateType, action: any) => {
        const serviceResponse = action.payload as ServiceResponse<IBooking>;
        state.booking = serviceResponse?.data;
      }
    );
    builder.addCase(
      setCustomer.fulfilled,
      (state: EcommerceStateType, action: any) => {
        const serviceResponse = action.payload as ServiceResponse<IBooking>;
        state.booking = serviceResponse?.data;
      }
    );
    builder.addCase(
      setBillingAddress.fulfilled,
      (state: EcommerceStateType, action: any) => {
        const serviceResponse = action.payload as ServiceResponse<IBooking>;
        state.booking = serviceResponse?.data;
      }
    );
  },
});

export const { getEcommerceState, updateBooking } = ecommerceSlice.actions;
export default ecommerceSlice.reducer;
