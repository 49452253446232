import { BOOKING_API_KEY, PLATFORM_ID } from "../../../config";
import { ServiceResponse } from "../../types";
import BookingClient from "../client";
import {
  ADD_SERVICE_TO_BOOKING,
  CONFIRM_BOOKING,
  GET_WEBSITE_BOOKING,
  REMOVE_BOOKING_ITEM,
  SET_BILLING_ADDRESS,
  SET_CUSTOMER,
  STOCK_CHECK,
  UPDATE_BOOKING_ITEM,
} from "./grahql";
import {
  AddServiceToBookingInput,
  ConfirmBookingInput,
  IBooking,
  RemoveBookingItemInput,
  SetBillingAddressInput,
  SetCustomerInput,
  StockCheckInput,
  UpdateBookingItemInput,
} from "./types";

const getWebsiteBooking = async (
  id: string,
  nextToken?: string
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: GET_WEBSITE_BOOKING,
          variables: {
            input: {
              id,
              platformId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getWebsiteBooking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const addServiceToBooking = async (
  input: AddServiceToBookingInput,
  nextToken?: string
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: ADD_SERVICE_TO_BOOKING,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    // OLLIE: maybe not best place for it?
    const booking = data.data.addServiceToBooking;
    booking.id && localStorage.setItem("fluidwbid", booking.id);

    return {
      data: data.data.addServiceToBooking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const removeBookingItem = async (
  input: RemoveBookingItemInput,
  nextToken?: string
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: REMOVE_BOOKING_ITEM,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    // OLLIE: maybe not best place for it?
    const booking = data.data.removeBookingItem;
    booking.id && localStorage.setItem("fluidwbid", booking.id);

    return {
      data: booking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const updateBookingItem = async (
  input: UpdateBookingItemInput,
  nextToken?: string
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: UPDATE_BOOKING_ITEM,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    // OLLIE: maybe not best place for it?
    const booking = data.data.updateBookingBookingItem as IBooking;
    booking.id && localStorage.setItem("fluidwbid", booking.id);

    return {
      data: booking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const setBillingAddress = async (
  input: SetBillingAddressInput,
  nextToken?: string
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: SET_BILLING_ADDRESS,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    // OLLIE: maybe not best place for it?
    const booking = data.data.setBillingAddress;
    booking.id && localStorage.setItem("fluidwbid", booking.id);

    return {
      data: booking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const setCustomer = async (
  input: SetCustomerInput
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: SET_CUSTOMER,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
          },
        },
      })
    )?.data;

    // OLLIE: maybe not best place for it?
    const booking = data.data.setCustomer;
    booking.id && localStorage.setItem("fluidwbid", booking.id);

    return {
      data: booking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const confirmBooking = async (
  input: ConfirmBookingInput
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: CONFIRM_BOOKING,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
          },
        },
      })
    )?.data;

    // OLLIE: maybe not best place for it?
    const booking = data.data.confirmBooking;

    return {
      data: booking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};
const stockCheck = async (
  input: StockCheckInput
): Promise<ServiceResponse<IBooking>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: STOCK_CHECK,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
          },
        },
      })
    )?.data;

    const booking = data.data.stockCheck as IBooking;

    return {
      data: booking,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const Service = {
  getWebsiteBooking,
  addServiceToBooking,
  setCustomer,
  removeBookingItem,
  setBillingAddress,
  confirmBooking,
  stockCheck,
  updateBookingItem,
};

export default Service;
