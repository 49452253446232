import React from "react";
import { Provider } from 'react-redux'

import 'react-tooltip/dist/react-tooltip.css'

import store from "./store";

const Wrapper = ({ element }: any) => {
    return (
        <Provider store={store}>
            {element}
        </Provider>
    );
};
export default Wrapper;
