import { Amplify } from "aws-amplify";

import {
  FetchUserAttributesOutput,
  fetchUserAttributes,
  signIn,
  signUp,
  signOut,
  fetchAuthSession,
  confirmSignUp as cognitoConfirmSignUp,
  confirmSignIn,
} from "@aws-amplify/auth";

import { ICustomer } from "../customer/customer/types";
import {
  AWS_USER_POOL_CLIENT_ID,
  AWS_USER_POOL_ID,
  PLATFORM_ID,
} from "../../config";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: AWS_USER_POOL_ID,
      userPoolClientId: AWS_USER_POOL_CLIENT_ID,
    },
  },
});

const getUser = async (): Promise<FetchUserAttributesOutput | undefined> => {
  try {
    const { userSub } = await fetchAuthSession();

    if (!userSub) {
      return undefined;
    }

    return await fetchUserAttributes();
  } catch (e) {
    console.error("cognito", "getUser", e);
  }
  return undefined;
};

const login = async (
  username: string
): Promise<FetchUserAttributesOutput | undefined> => {
  try {
    const output = await signIn({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    });

    if (output.isSignedIn) {
      return await fetchUserAttributes();
    }
  } catch (e) {
    console.error("cognito", "login", e);
  }
  return undefined;
};

const register = async (customer: ICustomer) => {
  try {
    return await signUp({
      username: `${PLATFORM_ID}-${customer?.id}`,
      password: `D3!g${getRandomString(30).toString()}`,
      options: {
        userAttributes: {
          given_name: customer.firstName,
          family_name: customer.lastName,
          email: customer.email,
          "custom:customer_id": customer.id,
          "custom:tenant_id": PLATFORM_ID,
        },
      },
    });
  } catch (e) {
    console.error("CognitoService", "register", e);
  }
  return undefined;
};
function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
}
function intToHex(nr: number) {
  return nr.toString(16).padStart(2, "0");
}

const logout = async () => {
  try {
    await signOut();
  } catch (e) {
    console.error("signOut", e);
  }
};

const getAuthToken = async () => {
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    return accessToken;
  } catch (e) {
    console.error("getAuthToken", e);
    return null;
  }
};

const confirmSignUp = async (
  username: string,
  confirmationCode: string
): Promise<boolean> => {
  try {
    const res = await cognitoConfirmSignUp({
      username,
      confirmationCode,
    });

    return res.isSignUpComplete;
  } catch (e) {
    console.error("confirmSignUp", e);
    return false;
  }
};

const answerCustomChallenge = async (challengeResponse: string) => {
  try {
    const res = await confirmSignIn({
      challengeResponse,
    });
    return res.isSignedIn;
  } catch (e) {}
  return false;
};

const CognitoService = {
  getUser,
  login,
  logout,
  register,
  getAuthToken,
  confirmSignUp,
  answerCustomChallenge,
};

export default CognitoService;
