import axios from 'axios';

import { BOOKING_API } from '../../config';

const BookingClient = axios.create({ baseURL: BOOKING_API });

BookingClient.interceptors.response.use(
  (res) => res,
  (error) => {
    console.error('BookingClient', 'interceptor', error);
    Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default BookingClient;
