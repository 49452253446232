
export const AWS_USER_POOL_ID = process.env.GATSBY_COGNITO_USER_POOL_ID || "";
export const AWS_USER_POOL_CLIENT_ID = process.env.GATSBY_COGNITO_USER_POOL_CLIENT_ID || "";

export const PLATFORM_ID = process.env.GATSBY_APP_PLATFORM_ID;
export const PLATFORM_API = process.env.GATSBY_APP_PLATFORM_API;
export const PB_API_KEY = process.env.GATSBY_APP_PLATFORM_API_KEY;

export const PAGE_BUILDER_API = process.env.GATSBY_APP_PAGE_BUILDER_API;
export const PAGE_BUILDER_API_KEY = process.env.GATSBY_APP_PAGE_BUILDER_API_KEY;

export const INTEGRATIONS_API = process.env.GATSBY_APP_INTEGRATIONS_API;
export const INTEGRATIONS_API_KEY = process.env.GATSBY_APP_INTEGRATIONS_API_KEY;

export const BOOKING_API = process.env.GATSBY_APP_BOOKING_API;
export const BOOKING_API_KEY = process.env.GATSBY_APP_BOOKING_API_KEY;

export const CUSTOMER_API = process.env.GATSBY_APP_CUSTOMER_API;
export const CUSTOMER_API_KEY = process.env.GATSBY_APP_CUSTOMER_API_KEY;

export const PAYMENT_SERVICES_API = process.env.GATSBY_APP_PAYMENT_SERVICES_API;
export const PAYMENT_SERVICES_API_KEY = process.env.GATSBY_APP_PAYMENT_SERVICES_API_KEY;
