import axios from 'axios';

import { CUSTOMER_API } from '../../config';

const CustomerClient = axios.create({ baseURL: CUSTOMER_API });

CustomerClient.interceptors.response.use(
  (res) => res,
  (error) => {
    console.error('CustomerClient', 'interceptor', error);
  }
);

export default CustomerClient;
