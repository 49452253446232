import { CUSTOMER_API_KEY, PLATFORM_ID } from "../../../config";
import CognitoService from "../../cognito";
import { ServiceResponse } from "../../types";
import CustomerClient from "../client";
import { CREATE_CUSTOMER, GET_CUSTOMER_BY_EMAIL } from "./grahql";
import { CreateCustomerInput, ICustomer } from "./types";

const createCustomer = async (
  input: CreateCustomerInput,
  nextToken?: string
): Promise<ServiceResponse<Pick<ICustomer, "cognitoUser">>> => {
  let data: any | undefined;
  try {
    data = (
      await CustomerClient.request({
        method: "post",
        headers: {
          "x-api-key": CUSTOMER_API_KEY,
        },
        data: {
          query: CREATE_CUSTOMER,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    return {
      data: data.data.createCustomerWebsite,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const getCustomerByEmail = async (
  email: string,
  nextToken?: string
): Promise<ServiceResponse<ICustomer>> => {
  let data: any | undefined;
  try {
    let headers: any = {
      "x-api-key": CUSTOMER_API_KEY,
    };

    const token = await CognitoService.getAuthToken();

    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    data = (
      await CustomerClient.request({
        method: "post",
        headers,
        data: {
          query: GET_CUSTOMER_BY_EMAIL,
          variables: {
            input: {
              email,
              platformId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    return {
      data: data.data.getByCustomerEmailWebsite,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const Service = { createCustomer, getCustomerByEmail };

export default Service;
