export const BOOKING_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment BookingFields on Booking {
    id
    status

    currencyCode

    subTotalAmount
    subTotalTax
    totalAmount
    totalTax

    customer {
      id
      firstName
      lastName
      email
      phoneNumber
    }

    billingAddress {
      id
      line1
      line2
      city
      postCode
      countryCode
    }

    items {
      id
      quantity

      currencyCode
      unitAmount
      subTotalAmount
      subTotalTax
      totalAmount
      totalTaxAmount

      linkedId

      service {
        id
        name
        depart
        mustBookAll
        route {
          from {
            id
            name
          }
          to {
            name
          }
        }
      }
    }

    errors {
      code
      message
    }
    stockErrors {
      code
      message
      data
    }

    metadata
  }
`;
export const GET_WEBSITE_BOOKING = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation GetWebsiteBooking($input: GetWebsiteBookingInput!) {
    getWebsiteBooking(input: $input) {
      ...BookingFields
    }
  }
`;
export const ADD_SERVICE_TO_BOOKING = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation AddServiceToBooking($input: AddServiceToBookingInput!) {
    addServiceToBooking(input: $input) {
      ...BookingFields
    }
  }
`;
export const REMOVE_BOOKING_ITEM = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation RemoveBookingItem($input: RemoveBookingItemInput!) {
    removeBookingItem(input: $input) {
      ...BookingFields
    }
  }
`;
export const UPDATE_BOOKING_ITEM = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation UpdateBookingBookingItem($input: UpdateBookingBookingItemInput!) {
    updateBookingBookingItem(input: $input) {
      ...BookingFields
    }
  }
`;
export const SET_BILLING_ADDRESS = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation SetBillingAddress($input: SetBillingAddressInput!) {
    setBillingAddress(input: $input) {
      ...BookingFields
    }
  }
`;
export const SET_CUSTOMER = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation SetCustomer($input: SetCustomerInput!) {
    setCustomer(input: $input) {
      ...BookingFields
    }
  }
`;

export const CONFIRM_BOOKING = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation confirmBooking($input: ConfirmBookingInput!) {
    confirmBooking(input: $input) {
      ...BookingFields
    }
  }
`;
export const STOCK_CHECK = /* GraphQL */ `
  ${BOOKING_FIELDS_FRAGMENT}
  mutation StockCheck($input: GetWebsiteBookingInput!) {
    stockCheck(input: $input) {
      ...BookingFields
    }
  }
`;
