import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AddServiceToBookingInput,
  IBooking,
} from "../../services/booking/booking/types";
import { BookingService } from "../../services/booking";
import { ServiceResponse } from "../../services/types";
import CognitoService from "../../services/cognito";
import { FetchUserAttributesOutput } from "@aws-amplify/auth";
import { ICustomer } from "../../services/customer/customer/types";
import { CustomerService } from "../../services/customer";

const STORE_NAME = "auth";

type AuthStateType = {
  user: FetchUserAttributesOutput | "INITIAL" | undefined;
  customer: ICustomer | { id: "INITIAL" } | undefined;
};

const INITIAL_STATE: AuthStateType = {
  user: "INITIAL",
  customer: {
    id: "INITIAL",
  },
};

export const isInitial = (obj: any) =>
  obj === "INITIAL" || obj?.id === "INITIAL";
export const authStateExists = (obj: any) => !isInitial(obj) && !!obj;

export const loadAuth = createAsyncThunk(
  STORE_NAME + "/loadAuth",
  async (inputEmail?: string) => {
    const user = await CognitoService.getUser();

    const userEmail = user?.email;

    if (inputEmail && userEmail !== inputEmail) {
      CognitoService.logout();
    }

    const searchEmail = inputEmail || userEmail;

    if (!searchEmail) {
      // this should only happen if the loadAuth is used for getting the customer based on the user and user is not logged in
      return undefined;
    }

    // get customer

    let response = await CustomerService.Customer.getCustomerByEmail(
      searchEmail
    );

    let customer = response?.data;

    return {
      user,
      customer,
    };
  }
);

const ecommerceSlice = createSlice({
  name: STORE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(loadAuth.fulfilled, (state: AuthStateType, action: any) => {
      const params = action?.payload as
        | Pick<AuthStateType, "customer" | "user">
        | undefined;
      state.user = params?.user;
      state.customer = params?.customer;
    });
  },
});

export const {} = ecommerceSlice.actions;
export default ecommerceSlice.reducer;
