export const CUSTOMER_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment CustomerFields on WebsiteCustomer {
    id
    firstName
    lastName
    email
    phoneNumber
    cognitoUser
  }
`;
export const CREATE_CUSTOMER = /* GraphQL */ `
  mutation CreateCustomerWebsite($input: CreateCustomerInput!) {
  createCustomerWebsite(input: $input) {
      cognitoUser
    }
  }
`;
export const GET_CUSTOMER_BY_EMAIL = /* GraphQL */ `
  ${CUSTOMER_FIELDS_FRAGMENT}
  query GetCustomerByEmailWebsite($input: GetByEmailCustomerInput!) {
    getByCustomerEmailWebsite(input: $input) {
      ...CustomerFields
    }
  }
`;
