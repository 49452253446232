import { BOOKING_API_KEY, PLATFORM_ID } from "../../../config";
import { ServiceResponse } from "../../types";
import BookingClient from "../client";
import {
  GET_WEBSITE_RETRUN_SERVICES,
  GET_WEBSITE_SERVICE,
  GET_WEBSITE_SERVICES,
  REQUEST_SERVICE,
} from "./graphql";
import {
  GetByServiceRouteAndDate,
  GetWebsiteServicesInput,
  IRequestServiceInput,
  IReturnService,
  IService,
} from "./types";

const getWebsiteService = async (
  id: string
): Promise<ServiceResponse<IReturnService>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: GET_WEBSITE_SERVICE,
          variables: {
            input: {
              id,
              tenantId: PLATFORM_ID,
              platformId: PLATFORM_ID,
            },
          },
        },
      })
    )?.data;

    return {
      data: data.data.getWebsiteService,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const getWebsiteServices = async (
  input: GetWebsiteServicesInput,
  nextToken?: string
): Promise<ServiceResponse<IReturnService[]>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: GET_WEBSITE_SERVICES,
          variables: {
            input,
            nextToken,
          },
        },
      })
    )?.data;

    return {
      ...data.data.getWebsiteServices,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const getWebsiteReturnServices = async (
  input: GetByServiceRouteAndDate,
  nextToken?: string
): Promise<ServiceResponse<IReturnService[]>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: GET_WEBSITE_RETRUN_SERVICES,
          variables: {
            input,
            nextToken,
          },
        },
      })
    )?.data;

    return {
      ...data.data.getWebsiteReturnServices,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const requestService = async (
  input: IRequestServiceInput,
  nextToken?: string
): Promise<ServiceResponse<boolean>> => {
  let data: any | undefined;
  try {
    data = (
      await BookingClient.request({
        method: "post",
        headers: {
          "x-api-key": BOOKING_API_KEY,
        },
        data: {
          query: REQUEST_SERVICE,
          variables: {
            input: {
              ...input,
              platformId: PLATFORM_ID,
              tenantId: PLATFORM_ID,
            },
            nextToken,
          },
        },
      })
    )?.data;

    return {
      data: !!data.data.requestService.id as boolean,
      success: true,
    };
  } catch (err) {
    return {
      success: false,
    };
  }
};

const Service = {
  getWebsiteService,
  getWebsiteServices,
  requestService,
  getWebsiteReturnServices,
};

export default Service;
